import React from 'react';
import { NotFound, Seo, SeoHidden } from 'components';

export default () => {
  return (
    <>
      <Seo title={'404'} description={'This page doesn’t exist'} />
      <NotFound />
    </>
  );
};
